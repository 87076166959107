export const bleed = {
  sm: '20px',
};

const baseStyle = {
  w: '100%',
  mx: 'auto',
  maxW: ['container.sm', 'container.md', 'container.lg', 'container.xl'],
  px: bleed.sm,
};

export default {
  baseStyle,
  bleed,
};
