import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';

export class AgendaSessions extends Resource {
  public static type = 'agenda_sessions';

  @prop
  public eventId: number;

  @prop
  public agendaId: number;

  @prop
  public eventSessionId: number;
}
