import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';
import { ProfilePlaylist } from '@/resources/ProfilePlaylist';
import { ThinLecture } from '@/resources/ThinLecture';

export class LecturePlaylist extends Resource {
  public static type = 'lecture_playlists';

  @prop
  public lectureId: number;

  @prop
  public playlistId: number;

  @prop.toOne('thin_lectures')
  public lecture: ThinLecture;

  @prop.toOne('profile_playlists')
  public playlist: ProfilePlaylist;
}
